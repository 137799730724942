import React from 'react'
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout'

import { GatsbyImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'

import SEO from '../components/seo'
import Utils from '../components/utils'


const Cate = ({ data, location, pageContext }) => {
  return (
    <Layout>

      <SEO
        pagetitle={`${pageContext.catname}の魂が震える名言・格言`}
        pagedesc={`${pageContext.catname}の魂が震える名言・格言`}
        pagepath={location.pathname}
      />


      <div className="l-wrap l-cate">

        <div className="l-box">
          <h2 className="m-heading">{pageContext.catname}の名言一覧</h2>
          <div className='m-quotes'>
            {data.posts.edges.map(( { node }) => {
              const num = Math.floor( Math.random() * Utils.color.length )
              return (
                <article className={`m-quotes__item ${Utils.color[num]}`} key={node.id}>
                  <Link to={`/${node.category.slug}/${node.slug}/`}>
                    <h3 className="m-quotes__hdg">
                      <FontAwesomeIcon icon={faQuoteLeft} />
                      <span className="m-quotes__txt">{node.title}</span>
                    </h3>
                  </Link>
                </article>
              )
            })}
          </div>
        </div>

        <div className="l-box l-box--column-2">
          <div className="l-box__data">

            <h2 className="m-heading">{pageContext.catname}</h2>
            <div className="m-profile">
              {data.cate.edges.map(( {node }) => {
                return (
                  <div className="m-profile__detail">
                    <div className="m-profile__img">
                      <figure>
                        <GatsbyImage
                          image={node.selfie.gatsbyImageData}
                          description={node.selfie.description}
                        />
                      </figure>
                    </div>
                    <div className="m-profile__texts">
                      <p className="m-profile__txt m-cite">
                        {node.informations.informations}
                      </p>
                      <p className="m-profile__sub">引用：<Link to="https://ja.wikipedia.org/wiki/%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%9A%E3%83%BC%E3%82%B8">Wikipedia</Link></p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </div>

      {/* <ul className="pagenation">
        {!pageContext.isFirst && (
          <li className="pagenation__item pagenation__item--prev">
            <Link
              to={
                pageContext.currentPage === 2
                  ? `/person/${pageContext.catslug}`
                  : `/person/${pageContext.catslug}/${pageContext.currentPage - 1}/`
              }
              rel="prev"
            >
              前のページへ
            </Link>
          </li>
        )}

        {!pageContext.isLast && (
          <li className="pagenation__item pagenation__item--next">
            <Link
              to={
                `/person/${pageContext.catslug}/${pageContext.currentPage + 1}/`
              }
              rel="next"
            >
              次のページへ
            </Link>
          </li>
        )}

      </ul> */}

    </Layout>

  )
}


export const query = graphql`
  query($catid: String!, $skip: Int!, $limit: Int!) {
    posts: allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      skip: $skip
      limit: $limit
      filter: {category: {id: {eq: $catid}}}
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
          category {
            slug
            name
            selfie {
              gatsbyImageData(layout: FULL_WIDTH)
              description
            }
          }
        }
      }
    }

    cate: allContentfulCategory(
      filter: {id: {eq: $catid}}
    ) {
      edges {
        node {
          id
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`


export default Cate;